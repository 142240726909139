import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
const TopBar = () => {
  const [key, setKey] = useState("true")
  const [localitySlug, setlocalitySlug] = useState("bengaluru")
  useEffect(() => {
    setKey(localStorage.getItem("topbarClose"))
  }, [])

  useEffect(() => {
    if (localStorage.getItem("slug")) {
      setlocalitySlug(localStorage.getItem("slug"))
    }
  }, [])
  const closeTopBar = () => {
    localStorage.setItem("topbarClose", true)
    setKey("true")
  }
  return (
    <div className="topBar">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <a
              href={"https://gofloaters.com/fwv/"}
              target="_blank"
              rel="noopener noreferrer nofollow"
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
            >
              Flexi Work Visionary: Are you or someone you know championing
              workplace flexibility?
            </a>
          </div>
          {/* <div className="col-1">
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={closeTopBar}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}
export default TopBar
