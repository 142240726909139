import React, { useContext, useEffect, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "lazysizes"
import AuthContext from "../contexts/AuthContext"
export const MobileMenu = props => {
  const [localitySlug, setlocalitySlug] = useState("bengaluru")
  const { signOutUser, user } = useContext(AuthContext)

  useEffect(() => {
    if (localStorage.getItem("slug")) {
      setlocalitySlug(localStorage.getItem("slug"))
    }
  }, [localitySlug])

  return (
    <ul className="navbar-nav ml-auto">
      {!user ? (
        <div>
          <br />

          <li className="nav-item">
            <Link
              to="/login/"
              title="Login"
              className="nav-link"
              style={{
                background: "#4f9fc8",
                padding: "10px 15px 10px 15px",
                color: "#fff",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              {" "}
              Login
            </Link>
          </li>
          <br />
        </div>
      ) : (
        ""
      )}
      <li className="nav-item">
        <p className="nav-link" style={{ fontWeight: "bold" }}>
          Book
        </p>
      </li>
      <li className="nav-item">
        <Link
          to={"/coworking-spaces/" + localitySlug + "/all"}
          className="nav-link"
        >
          <img
            src="https://assets.gofloaters.com/icons/menuIcons/coworking-spaces.png"
            alt="Coworking Spaces"
            style={{ width: "30px" }}
          ></img>{" "}
          Coworking Spaces
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={"/meeting-spaces/" + localitySlug + "/all"}
          className="nav-link"
        >
          <img
            src="https://assets.gofloaters.com/icons/menuIcons/meeting-spaces.png"
            style={{ width: "30px" }}
          ></img>{" "}
          Meeting Spaces
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={"/office-spaces/" + localitySlug + "/all"}
          className="nav-link"
        >
          <img
            src="https://assets.gofloaters.com/icons/menuIcons/office-spaces.png"
            alt="Office Spaces"
            style={{ width: "30px" }}
          ></img>{" "}
          Office Spaces
        </Link>
      </li>

      {!user ? (
        ""
      ) : (
        <div>
          <li className="nav-item">
            <p className="nav-link" style={{ fontWeight: "bold" }}>
              Account
            </p>
          </li>
          <li className="nav-item">
            <Link
              to="/my-profile/"
              title="Events"
              className="nav-link teamLinksNav"
            >
              <img
                src="https://assets.gofloaters.com/icons/menuIcons/my-profile.png"
                style={{ width: "30px" }}
              ></img>{" "}
              My Profile
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/bookings/"
              title="Events"
              className="nav-link teamLinksNav"
            >
              <img
                src="https://assets.gofloaters.com/icons/menuIcons/my-booking.png"
                style={{ width: "30px" }}
              ></img>{" "}
              My Bookings
            </Link>
          </li>
          <li className="nav-item">
            <a
              href="#"
              onClick={() => {
                signOutUser()
                if (typeof window !== `undefined`) {
                  window.location.reload()
                }
              }}
              title="Events"
              className="nav-link teamLinksNav"
            >
              <img
                src="https://assets.gofloaters.com/icons/menuIcons/logout.png"
                style={{ width: "30px" }}
              ></img>{" "}
              Logout
            </a>
          </li>
        </div>
      )}

      <li className="nav-item">
        <p className="nav-link" style={{ fontWeight: "bold" }}>
          Resources
        </p>
      </li>
      <li className="nav-item">
        <Link to="/worqflexi/" className="nav-link teamLinksNav">
          <img
            src="https://assets.gofloaters.com/icons/menuIcons/resources.png"
            alt="Resource Icon"
            style={{ width: "30px" }}
          ></img>{" "}
          WorqFlexi<span>New</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/virtual-office/" className="nav-link teamLinksNav">
          <img
            src="https://assets.gofloaters.com/icons/menuIcons/resources.png"
            alt="Resource Icon"
            style={{ width: "30px" }}
          ></img>{" "}
          Virtual Offices
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="/chennai-managed-offices-report/"
          className="nav-link teamLinksNav"
        >
          <img
            src="https://assets.gofloaters.com/icons/menuIcons/resources.png"
            alt="Resource Icon"
            style={{ width: "30px" }}
          ></img>{" "}
          Chennai Managed Offices Report<span>New</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/hybrid-report/" className="nav-link teamLinksNav">
          <img
            src="https://assets.gofloaters.com/icons/menuIcons/resources.png"
            alt="Resource Icon"
            style={{ width: "30px" }}
          ></img>{" "}
          India Hybrid Work Report
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/blog/" className="nav-link teamLinksNav">
          <img
            src="https://assets.gofloaters.com/icons/menuIcons/resources.png"
            alt="Resource Icon"
            style={{ width: "30px" }}
          ></img>{" "}
          Blog
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="/case-studies/"
          title="Events"
          className="nav-link teamLinksNav"
        >
          <img
            src="https://assets.gofloaters.com/icons/menuIcons/resources.png"
            alt="Resource Icon"
            style={{ width: "30px" }}
          ></img>{" "}
          Case Studies
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="https://www.gofloaters.com/fwv/"
          target="_blank"
          rel="noopener noreferrer nofollow"
          title="Events"
          className="nav-link teamLinksNav"
        >
          <img
            src="https://assets.gofloaters.com/icons/menuIcons/resources.png"
            alt="Resource Icon"
            style={{ width: "30px" }}
          ></img>{" "}
          Flexi Work Visionaries <span>New</span>
        </Link>
      </li>
      {/* {!user ? (
        <li className="nav-item">
          <Link
            to="/login/"
            title="Login"
            className="nav-link"
            style={{
              background: "#4f9fc8",
              padding: "10px 15px 10px 15px",
              color: "#fff",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            {" "}
            Login
          </Link>
        </li>
      ) : (
        ""
      )} */}
    </ul>
  )
}

export const DesktopMenu = props => {
  const [localitySlug, setlocalitySlug] = useState("bengaluru")
  const { signOutUser, user } = useContext(AuthContext)
  useEffect(() => {
    if (localStorage.getItem("slug")) {
      setlocalitySlug(localStorage.getItem("slug"))
    }
    // if (user?.organizationId) {
    //   alert(
    //     "You are a WorqFlexi (B2B) user. You must use the WorqFlexi mobile apps to make your bookings. You are being logged out"
    //   )
    //   signOutUser()
    //   if (typeof window !== `undefined`) {
    //     window.location.reload()
    //   }
    // }
  }, [localitySlug])

  useEffect(() => {
    if (user) {
      if (user.organizationId) {
        alert(
          "You are a WorqFlexi (B2B) user. You must use the WorqFlexi mobile apps to make your bookings. You are being logged out"
        )
        // signOutUser()
        // window.location.reload()
      }
    }
  }, [user])
  const [menuState, setmenuState] = useState("close")
  const MenuOpen = () => {
    if (menuState === "open") {
      setmenuState("close")
    } else {
      setmenuState("open")
    }
  }
  const lists = props.data.allListings ? props.data.allListings.edges : []
  const cities = props.data.allCities.edges
  const topCity = ["Bangalore", "Mumbai", "Pune", "Chennai", "Hyderabad"]
  return (
    <div className="collapse navbar-collapse" id="navbarToggler">
      <ul className="navbar-nav mr-auto">
        <li className="nav-item dropdown mr-auto">
          <a
            href="#"
            className="dropdown-toggle nav-link"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Book
          </a>
          <ul className="dropdown-menu dropdown-menu-left">
            <li>
              <Link
                to={"/coworking-spaces/" + localitySlug + "/all"}
                style={{ paddingLeft: "0" }}
              >
                Coworking Day Pass
              </Link>
            </li>
            <li>
              <Link
                to={"/meeting-spaces/" + localitySlug + "/all"}
                style={{ paddingLeft: "0" }}
              >
                Meeting Rooms
              </Link>
            </li>
            <li>
              <Link
                to={"/office-spaces/" + localitySlug + "/all"}
                style={{ paddingLeft: "0" }}
              >
                Office On Rent
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item dropdown mr-auto">
          <a
            href="#"
            className="dropdown-toggle nav-link"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Solutions
          </a>
          <ul className="dropdown-menu dropdown-menu-left">
            <li>
              <Link
                to="/worqflexi"
                style={{ paddingLeft: "0" }}
                className="nav-link teamLinksNav"
              >
                Hybrid Office
              </Link>
            </li>
            <li>
              <Link to="/virtual-office" style={{ paddingLeft: "0" }}>
                Virtual Offices
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item dropdown mr-auto">
          <a
            href="#"
            className="dropdown-toggle nav-link"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Brands
          </a>
          <ul className="dropdown-menu dropdown-menu-left">
            <li>
              <Link to="/brand/wework" style={{ paddingLeft: "0" }}>
                WeWork
              </Link>
            </li>
            <li>
              <Link to="/brand/cowrks" style={{ paddingLeft: "0" }}>
                Cowrks
              </Link>
            </li>
            <li>
              <Link to="/brand/awfis" style={{ paddingLeft: "0" }}>
                AWFIS
              </Link>
            </li>
            <li>
              <Link to="/brand/91springboard" style={{ paddingLeft: "0" }}>
                91Springboard
              </Link>
            </li>

            <li>
              <Link to="/brand/innov8" style={{ paddingLeft: "0" }}>
                Innov8
              </Link>
            </li>
          </ul>
        </li>
        <li className="nav-item dropdown mr-auto">
          <a
            href="#"
            className="dropdown-toggle nav-link"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Customers
          </a>
          <ul className="dropdown-menu dropdown-menu-right">
            <li>
              <Link to="/case-studies/" title="Events">
                Case Studies
              </Link>
            </li>
            <li>
              <Link to="/customer-stories/" title="Events">
                Testimonials
              </Link>
            </li>
          </ul>
        </li>
        <li className="nav-item dropdown mr-auto">
          <a
            href="#"
            className="dropdown-toggle nav-link"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Resources
          </a>
          <ul className="dropdown-menu dropdown-menu-right">
            <li>
              <Link to="/blog/" title="Events">
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/chennai-managed-offices-report"
                style={{ paddingLeft: "0" }}
                className="nav-link teamLinksNav"
              >
                Chennai Managed Offices Report <span>New</span>
              </Link>
            </li>
            <li>
              <Link
                to="/hybrid-report/"
                title="Events"
                style={{ paddingLeft: "0" }}
                className="nav-link teamLinksNav"
              >
                Hybrid Work Report <span>New</span>
              </Link>
            </li>
            <li>
              <Link to="/events/" title="Events">
                Events
              </Link>
            </li>
          </ul>
        </li>
        <li className="nav-item dropdown mr-auto">
          <Link
            to="https://www.gofloaters.com/fwv/"
            target="_blank"
            rel="noopener noreferrer nofollow"
            title="Events"
            className="nav-link teamLinksNav"
          >
            Flexi Work Visionaries <span>New</span>
          </Link>
        </li>
      </ul>

      {!user ? (
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link
              to="/login/"
              title="Login"
              className="nav-link"
              style={{
                background: "#4f9fc8",
                padding: "5px 15px 5px 15px",
                color: "#fff",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              {" "}
              Login
            </Link>
          </li>
        </ul>
      ) : (
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown mr-auto">
            <Link to="/my-profile/" title="Events">
              My Profile
            </Link>
          </li>
          <li className="nav-item dropdown mr-auto">
            <Link to="/bookings/" title="Events">
              My Bookings
            </Link>
          </li>
          <li className="nav-item dropdown mr-auto">
            <a
              href="#"
              onClick={() => {
                signOutUser()
                if (typeof window !== `undefined`) {
                  window.location.reload()
                }
              }}
              title="Logout"
            >
              <i class="fa fa-sign-out" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      )}
    </div>
  )
}
